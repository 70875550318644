.geosuggest {
  .geosuggest__suggests-wrapper {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: none;
    text-align: left;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .menu {
      cursor: auto;
      position: absolute;
      outline: none;
      top: 100%;
      min-width: -webkit-max-content;
      min-width: -moz-max-content;
      min-width: max-content;
      margin: 0em;
      padding: 0em 0em;
      background: #ffffff;
      font-size: 1em;
      text-shadow: none;
      text-align: left;
      -webkit-box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
      box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      -webkit-transition: opacity 0.1s ease;
      transition: opacity 0.1s ease;
      z-index: 11;
      will-change: transform, opacity;
    }

    .menu .selected.item {
      background: rgba(0, 0, 0, 0.03);
      color: rgba(0, 0, 0, 0.95);
    }

    .menu .item:first-child {
      border-top-width: 0px;
    }

    .menu .item {
      position: relative;
      cursor: pointer;
      display: block;
      border: none;
      border-top-color: currentcolor;
      border-top-style: none;
      border-top-width: medium;
      height: auto;
      text-align: left;
      border-top: none;
      border-top-width: medium;
      line-height: 1em;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.78571429rem 1.14285714rem !important;
      font-size: 1rem;
      text-transform: none;
      font-weight: normal;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-touch-callout: none;
    }
    .menu .item {
      white-space: nowrap;
    }
  }


  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    display: none !important;
  }

  .geosuggest__item {
  }
}
