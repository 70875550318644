.form.success {
  animation: backgroundFadeSuccess 1s;
}

.form.failed {
  animation: backgroundFadeError 1s;
}

.nested-form.success {
  animation: backgroundFadeSuccess 1s;
}

.nested-form.failed {
  animation: backgroundFadeError 1s;
}

@keyframes backgroundFadeSuccess {
    from { background-color: $successBackgroundColor; }
    to   { background-color: $white; }
}

@keyframes backgroundFadeError {
    from { background-color: $errorBackgroundColor; }
    to   { background-color: $white; }
}
