
@import 'variables';

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  background: $brown -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  background: $brown linear-gradient(transparent, rgba(0, 0, 0, 0.05));
}

.ui.form {
  .fields {
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    .field {
      padding-left: 0px !important;
    }
  }
}

.ui.menu {
  .item {
    &:before {
      width: 0px;
      background: none !important;
    }
  }

  border: none !important;
  border-radius: 0px !important;
}

.ui.menu .menu a.item:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.95);
}

.field.compact {
  .ui.input {
    input {
      padding-top: 0.589em;
      padding-bottom: 0.589em;
    }
  }
}

/*!
 * # Range slider for Semantic UI.
 *
 */
.ui.range {
	width: 100%;
	height: 20px;
	background: #2185D0
}

.ui.range .inner {
	margin: 0 10px 0 10px;
	height: 20px;
	position: relative;
}

.ui.range .inner:hover {
	cursor: pointer;
}

.ui.range .inner .track {
	position: absolute;
	width: 100%;
	height: 4px;
	border-radius: 4px;
	top: 9px;
	left: 0;
	background-color: rgba(0,0,0,.05);
}

.ui.inverted.range .inner .track {
	background-color: rgba(255,255,255,.08);
}

.ui.range .inner .track-fill {
	position: absolute;
	width: 0;
	height: 4px;
	border-radius: 4px;
	top: 9px;
	left: 0;
	background-color: #1b1c1d;
}

.ui.inverted.range .inner .track-fill {
	background-color: #545454;
}

.ui.range .inner .thumb {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background: #fff linear-gradient(transparent, rgba(0, 0, 0, 0.05));
	background: #fff -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
	background: #fff -o-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
	background: #fff -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
	border-radius: 100%;
	box-shadow: 0 1px 2px 0 rgba(34,36,38,.15),0 0 0 1px rgba(34,36,38,.15) inset;
}
