.ui.form .DraftEditor-root {
    vertical-align: top;
}
.ui.form .DraftEditor-root {
    margin: 0em;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    padding: 0.78571429em 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    font-size: 1em;
    line-height: 1.2857;
    resize: vertical;
}
.ui.form .DraftEditor-root {
    width: 100%;
    vertical-align: top;
}
.ui.form .DraftEditor-root:focus {
    color: rgba(0, 0, 0, 0.95);
    border-color: #85B7D9;
    border-radius: 0.28571429rem;
    background: #FFFFFF;
    box-shadow: 0px 0em 0em 0em rgba(34, 36, 38, 0.35) inset;
    -webkit-appearance: none;
}
