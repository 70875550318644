@import 'variables';
@import '~react-big-calendar/lib/css/react-big-calendar';
@import '~draft-js/dist/Draft';
@import '~dropzone/dist/dropzone';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

@import 'components/Login';
@import 'components/agenda/EventsCalendar';
@import 'components/forms/DossierEditor';
@import 'components/forms/SchemaForm';
@import 'components/forms/Timeline';
@import 'components/forms/widgets/SemanticDraftWidget';
@import 'components/forms/widgets/SemanticGeocoderWidget';
@import 'components/forms/widgets/SemanticTimelineWidget';


$mainMenuHeight: 8vh;
$mainBodyHeight: 82vh;
$managementMenuHeight: 10vh;

body {
  width: 100%;
  overflow-y: hidden;
}

.ui.menu.main-menu {
  height: $mainMenuHeight;
  margin-bottom: 0px;
}

.ui.bottom.sidebar.menu.management-menu {
  height: $managementMenuHeight !important;
}

.ui.bottom.sidebar.management-menu {
  .row {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .column {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.main {
  height: $mainBodyHeight;
  overflow-y: hidden;
  .main-body {
    width: 100%;
    height: $mainBodyHeight;
    overflow-y: scroll;
  }
}

.full-main {
  height: 100vh;
  overflow-y: scroll;
}

.no-padding {
  padding: 0 !important;
}

.absolute-top {
  position: absolute;
  top: 0;
}

.min-height-90 {
  min-height: $mainBodyHeight;
}

.min-height-50 {
  min-height: 50vh;
}

.left-sidebar-margin {
  margin-right: 260px;
}

.rbc-toolbar {
  .buttons {
    background-color: white;
  }
}

.rbc-time-view {
  background-color: white;
}

.rbc-month-view {
  background-color: white;
}

.draglayer {
}

.unselectable {
  pointer-events: none;
  user-select: none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
}

blockquote {
  background: #f9f9f9;
  border-left: 2px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

