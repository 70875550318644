.timeline-picker {
  opacity: 0;
  transition: opacity 0.2s;
}

.timeline-picker--loaded { // show the timeline-picker after dates position has been set (using JavaScript)
  opacity: 1;
}

.timeline-picker__container {
  position: relative;
  margin-top: 30px;
}

.timeline-picker__options {
  position: relative;
  height: 4px;
  margin: 0 40px;
}

.timeline-picker__period{ // blue filling line
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: auto;
  background-color: $grey;
  transition: transform 0.3s;
}

.timeline-picker__option-label {
  position: absolute;
  bottom: 0; // left position will be set using JavaScript
  z-index: 10;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: 1em;
  color: $textGrey;
  user-select: none; // improve swipe
  text-decoration: none;
  cursor: pointer;

  &--left {
    left: 0;
    border-left: 1px solid $grey;
    padding-left: 5px;
  }
  &--right {
    right: 0;
    border-right: 1px solid $grey;
    padding-right: 5px;
  }
}

.timeline-picker__option {

  &--left {
    left: -6px;
  }

  &--right {
    right: -6px;
  }
    content: '';
    position: absolute;
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: $blue;
    background-color: $white;
    transition: background-color 0.3s, border-color .3s;
    z-index: 10;

  &:hover {
    background-color: $blue;
    border-color: $blue;
  }
}

.timeline-picker__period--selected {
  background-color: $blue;

  &::after {
    background-color: $blue;
    border-color: $blue;
  }

  .timeline-picker__option {
    &::after {
      background-color: $blue;
      border-color: $blue;
    }
  }
}

.timeline-picker__navigation { // arrows to navigate the timeline-picker
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border-width: 2px;
  border-style: solid;
  transition: border-color 0.3s;

  .ui.icon { // arrow icon
    position: absolute;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
  }

  &:hover {
    border-color: $blue;
  }
}

.timeline-picker__navigation--prev {
  left: 0;
}

.timeline-picker__navigation--next {
  right: 0;
}

.timeline-picker__navigation--inactive {
  cursor: not-allowed;

  &::after {
    background-position: 0 -16px;
  }

  &:hover {
    border-color: $brown;
  }
}
