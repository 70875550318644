blockquote {
  background: #f9f9f9;
  border-left: 2px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

.editor-draglayer {
  &.with-sidebar {
    transform: transform3d(0,-260px, 0);
    position: 'fixed';
    pointerEvents: 'none';
    zIndex: 100;
    left: 0;
    top: 0;
    borderColor: 'blue';
    borderWidth: '10px';
    borderStyle: 'solid';
    width: '100%';
    height: '100%';
  }
}

.editor-core {
  .ui.placeholder.segment {
    min-height: 4rem;
    margin-bottom: 1rem;
  }

  .ui.segment.editor-node {
    &.editorial {
      border-left: 2px solid;
    }
    margin-bottom: 1rem;

    .editor-node-icon {
      position: absolute;
      top: 3px;
      left: 3px;
      cursor: move;
    }

    .editor-node-handle-icon {
      position: absolute;
      top: 3px;
      right: 3px;
      cursor: move;
    }

    .editor-node-remove-icon {
      color: $black;
      position: absolute;
      bottom: 3px;
      left: 3px;
      cursor: pointer;
      width: 15px;
      height: 15px;
      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }

    &.note {
      border-color: $brown;
      .editor-node-icon {
        color: $brown;
      }
    }

    &.attachment {
      border-color: $blue;
      .editor-node-icon {
        color: $blue;
      }
    }
  }
}
