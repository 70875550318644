.Timeline-line {
  z-index: -1;
  position: absolute;
  width: 3px;
  left: 30%;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
}

.Timeline-icon {
  position: absolute;
  left: 10%;
  right: 0;
  top: 2.5em;
}

.Timeline-date {
  position: absolute;
  top: 6.5em;
  background-color: grey;
  color: white;
  padding: 3px;
  left: 30%;
  border-radius: 3px;
  margin: auto;
  white-space: nowrap;
}

.Timeline-hour {
  position: absolute;
  top: 9em;
  left: 30%;
  background-color: grey;
  color: white;
  padding: 3px;
  border-radius: 3px;
  margin: auto;
}

.Timeline-node {
  min-height : 12em;
  &.Timeline-first {
    .Timeline-line {
      top: 1.5em;
    }
  }

  &.Timeline-last {
    .Timeline-line {
      height: 9em;
    }
  }
}


.ui.grid > .row.Timeline-node {
  padding-top: 0em;
  padding-bottom: 0em;
}

