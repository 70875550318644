// Big calendar styling

.rbc-calendar {
  min-height: 500px;
}

.full-width {
  position: absolute;
  width: 100%;
}
